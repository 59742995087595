import React from "react";
import "./formsubmitted.css"

const FormSubmiitted = () => {
  return (
    <div className="submittedDiv">
      <img
        src="images/thankyou.png"
        className="thankyouimg"
      />
      <h1 className="thankyoutext">Thank you for submitting your Onboarding form! We will be in touch shortly with next steps.</h1>
    </div>
  );
};

export default FormSubmiitted;
